
// Genericas
import './css/App.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// API
import APICALL from './APICALL';

export default function Formulario() {

  const navigate = useNavigate();
  const [Form, setForm] = useState([]);


  // Modificacion contenido Formulario
  const handleInputChange = (index, field, value) => {
    const updatedForm = [...Form];
    updatedForm[index][field] = value;
    setForm(updatedForm);
  };
  const handleCheckboxChange = (blockIndex, optionIndex, isChecked) => {
    setForm(prevForm => {
      const updatedForm = [...prevForm];
      updatedForm[blockIndex].alternatives[optionIndex].selected = isChecked;
      return updatedForm;
    });
  };
  const handleRadioChange = (blockIndex, selectedOptionIndex) => {
    setForm(prevForm => {
      const updatedForm = [...prevForm];
      updatedForm[blockIndex].alternatives = updatedForm[blockIndex].alternatives.map((alt, optIndex) => ({
        ...alt,
        selected: optIndex === selectedOptionIndex
      }));
      return updatedForm;
    });
  };


  // Guardado Formulario
  async function handlesubbmit(event) {
    event.preventDefault();
  

    for (const block of Form) {
      if (block.type === 'Alt Multiple' && block.required === "Requerido") {
        const hasSelectedOption = block.alternatives.some(a => a.selected);
        if (!hasSelectedOption) {
          window.alert(`Debes seleccionar al menos una opción en: "${block.title}"`);
          return;
        }
      }
    }
  
    try {
      await APICALL({
        apicall: 'FM_submmit',
        Name: sessionStorage.getItem('Nombre'),
        FormID: sessionStorage.getItem('FID'),
        Formreply: Form
      });
  
      navigate('/End'); 
    } catch (err) {
      window.alert(err);
    }
  }
  


  useEffect(() => {
    const fetchData = async () => {

      const data2 = await APICALL({
        apicall: 'FM_fetch',
        code: sessionStorage.getItem("process"),
        U_emp: sessionStorage.getItem('Grupo'),
        FormID: sessionStorage.getItem('FID')
      });

      setForm(data2);
    };

    fetchData();
    // eslint-disable-next-line
  }, []);


  return (
    <div className="BackGround">

      {/* Titulo */}
      {/* <div className="typing-effect-container">
        <h2 className="typing-effect">
        </h2>
      </div> */}

      {/* Contenido Pagina */}
      <form id='myform' className='MainBody' onSubmit={handlesubbmit}>
        <div style={{ overflow: 'scroll', width:'100%', overflowX: 'hidden' }}>
          <div>
          <br/>
          <h2>🎉🎉🎉<br/>
          Felicitaciones! has pasado a la fase de evaluacion para el Cargo al que postulaste,<br/> por favor completa este formulario de evaluación <br/>
          🎉🎉🎉<br/></h2>
          <br/>
          <br/>
          {Form.length > 0 && (
            <div  className='Formbody'>
              {Form.map((block, index) => (
                <div
                  key={index}
                  className='notas'
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '0vh 1vh 2vh 0vh',
                    gap: '1vh',
                    overflow:'hidden',
                    alignItems:'flex-end'
                  }}>
                  <div style={{ flex: 1 }}>

                    {/* Titulo */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span
                        className='notas-title'
                        style={{ marginLeft: '1vh', fontWeight: 'bold', height:'fit-content', marginBottom:'1vh' }}>
                        {block.title}
                      </span>
                    </div>

                    {/* Respuesta */}
                    {block.type === 'Texto' ? (
                      <div>
                        <textarea
                          value={block.content}
                          className='notas-content'
                          onChange={(e) => handleInputChange(index, 'content', e.target.value)}
                          placeholder="Escribe tu respuesta"
                          rows="5"
                          required={block.required === "Requerido"}
                          style={{ height: '15vh', marginBottom: '1vh', resize: 'none'}}
                        />
                        {block.required === "Requerido" && !block.content && (
                        <span style={{ color: 'red', fontSize: '1vh' }}>Debes responder esta respuesta</span>)}
                      </div>
                    ) : block.type === 'Alt Unica' ? (
                      <div className="alternatives-container">
                        {block.alternatives && block.alternatives.map((alternative, optIndex) => (
                          <label key={optIndex} className={ alternative.selected ? 'alternative-label blue' : 'alternative-label' }>
                            <input
                              type="radio"
                              className="alternative-checkbox"
                              name={`radio-group-${index}`} 
                              checked={alternative.selected || false}
                              required={block.required === "Requerido"}
                              onChange={(e) => handleRadioChange(index, optIndex, e.target.checked)}
                            />
                            {alternative.label}
                          </label>
                        ))}
                        {block.required === "Requerido" && block.alternatives.every(a => !a.selected) && (
                        <span style={{ color: 'red', fontSize: '1VH' }}>Debes seleccionar una de las opciónes</span>)}
                      </div>
                    ) : block.type === 'Alt Multiple' ? (
                      <div className="alternatives-container">
                        {block.alternatives && block.alternatives.map((alternative, optIndex) => (
                          <label key={optIndex} className={ alternative.selected ? 'alternative-label blue' : 'alternative-label' }>
                            <input
                              type="checkbox"
                              className="alternative-checkbox"
                              checked={alternative.selected || false}
                              onChange={(e) => handleCheckboxChange(index, optIndex, e.target.checked)}
                            />
                            
                            {alternative.label}
                          </label>
                        ))}
                        {block.required === "Requerido" && block.alternatives.every(a => !a.selected) && (
                        <span style={{ color: 'red', fontSize: '1VH' }}>Debes seleccionar al menos una opción</span>)}
                      </div>
                    ) : null}
                    
                  </div>
                </div>
              ))}
            </div>
          )}
          </div>
          <button style={{padding:'2vh'}} 
                  onClick={() => {const form = document.getElementById('myform'); if (form) {form.requestSubmit()}}}>
            ENVIA TUS RESPUESTAS
          </button>
        </div>
      </form>

      {/* Botones fondo */}


    </div>
  );
}
